
import request from '@/utils/request'

export function getDictItemList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/dict/queryDictItemList',
    method: 'post',
    data: params
  })
}

export function queryOrgRelTree(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/org/rel/queryOrgRelTree',
    method: 'post',
    data: params
  })
}

